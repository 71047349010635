.add-company-admin {
  .add-admin-form {
    width: 500px;
  }
  input {
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    padding-left: 1rem;
    outline: none;
    border: 1px solid gray;
  }
  .required {
    color: red;
  }
}
