// @import '~bootstrap/scss/bootstrap.scss';
@import '@fortawesome/fontawesome-free';
@import 'login.scss';
@import 'forgot_password.scss';
@import 'pagy.scss';
@import 'reports_management.scss';
@import 'sales_report.scss';
@import 'manage_users.scss';
@import 'pagy.scss';
@import 'reports_management.scss';
@import 'change_password.scss';
@import 'clients_users_management.scss';
@import 'new_company.scss';
@import 'company_invitation.scss';
@import 'add_company_admin.scss';
@import 'paid_button.scss';
@import "admin-lte";

.fs-10 {
  font-size: 10px;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fw-700 {
  font-weight: 700;
}

.border-radius-10 {
  border-radius: 10px !important;
}

button, input[type="submit"], a {
  transition: filter 0.3s;
}

/* Hover effect to darken the button */
button:hover, input[type="submit"]:hover, a:hover {
  filter: brightness(93%);
}

.nav-active {
  background-color: rgb(88, 89, 92) !important;
}
