// Paid button
.paid-container {
  background-color: #339063;
  border-radius: 3rem;
  width: 86px;
  height: 44px;
  display: flex;
  position: relative;
  align-items: center;
  padding-right: 4px;
  padding-left: 4px;
  transition: all 0.6s ease;
}
.paid-container:hover {
  cursor: pointer;
  opacity: 0.9;
}
.paid-button {
  position: absolute;
  right: 4px;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background-color: #FAD961;
  background-image: linear-gradient(90deg, #FAD961 0%, #F76B1C 100%);
  transition: all 0.6s ease;
}
.paid-container.inactive {
  justify-content: start;
  background-color: #acacac;
  transition: all 0.6s ease;
}
.paid-button.inactive {
  transform: translateX(-41px) rotate(720deg);
  background-color: #339063;
  transition: all 0.6s ease;
  transform-origin: center;
}
.paid-text {
  height: 40px;
  text-align: center;
  line-height: 40px; 
}
