body {
  font-family: Arial, sans-serif;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form-container {
  width: 500px;
  padding: 30px;
  border-radius: 20px;
  background-color: #1B4D94;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  margin: auto;
  color: white;
}

.text-h {
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px;
}

.tx-message {
  text-align: center;
  margin-bottom: 15px;
}

.success-message {
  color: #28a745;
}

.error-message {
  color: #dc3545;
}

.field input[type="email"],
.field input[type="password"] {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 15px;
  box-sizing: border-box;
}

.log-in {
  width: 100%;
  max-width: 120px;
  height: 30px;
  padding: 5px 19px;
  border: none;
  border-radius: 10px;
  background-color: #fff;
  color: #1B4D94;
  cursor: pointer;
  line-height: 1;
}

.check-box {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.hv-check{
  cursor: pointer;
}

.fg-password {
  display: block;
  text-align: center;
  margin-top: 10px;
  color: #007bff;
  text-decoration: none;
}

h2 {
  margin-top: 0;
}

label:not(.form-check-label):not(.custom-file-label) {
  font-weight: normal;
}
