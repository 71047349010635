// Pagy style
.pagy-nav {
  display: flex;
  font-family: sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  color: rgb(107 114 128);
  & > :not([hidden]) ~ :not([hidden]) {
    --space-reverse: 0;
    margin-right: calc(0.25rem * var(--space-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--space-reverse)));
  }
  .page.active {
    width: 33px;
    text-align: center;
    line-height: 28px;
    border-radius: 10px;
    background-color: #87ccaa;
  }
  a:not(.gap) {
    display: block;
    text-decoration: none;
    border-radius: 0.5rem;
    background-color: rgb(229 231 235);
    padding: 0.25rem 0.75rem;
    color: inherit;
    &:hover {
      background-color: rgb(209 213 219);
    }
    &:not([href]) { /* disabled links */
      cursor: default;
      background-color: rgb(243 244 246);
      color: rgb(209 213 219);
    }
    &.current {
      background-color: rgb(156 163 175);
      color: rgb(255 255 255);
    }
  }

  label {
    white-space: nowrap;
    display: inline-block;
    border-radius: 0.5rem;
    background-color: rgb(229 231 235);
    padding: 0.125rem 0.75rem;
    input {
      line-height: 1.5rem;
      border-radius: 0.375rem;
      border-style: none;
      background-color: rgb(243 244 246);
    }
  }
  .page.disabled {
    display: none
  }
}
