.sales-report {
  .paid-container {
    background-color: #339063;
    border-radius: 3rem;
    width: 86px;
    height: 44px;
    display: flex;
    justify-content: end;
    align-items: center;
    padding-right: 4px;
    padding-left: 4px;
    transition: all 0.4s ease;
  }
  .paid-container:hover {
    cursor: pointer;
    opacity: 0.9;
  }
  .paid-button {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    background-color: white;
    transition: all 0.4s ease;
  }
  .paid-container.inactive {
    justify-content: start;
    background-color: #acacac;
    transition: all 0.4s ease;
  }
  .paid-button.inactive {
    background-color: #339063;
    transition: all 0.4s ease;
  }
  .paid-text {
    height: 40px;
    text-align: center;
    line-height: 40px; 
  }
  .paid-outer-container {
    margin-left: 112px;
  }
  .search-button {
    background-color: #F47E29;
    width: 93px;
    height: 30px;
    color: white;
    border: none;
  }
  .select-year {
    width: 120px;
    height: 22px;
  }
  .select-month {
    width: 108px;
    height: 22px;
  }
  .container-92px{
    height: 92px;
  }
  .sale-search-button:hover {
    opacity: 0.8;
  }
}
