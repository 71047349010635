.gap-20 {
	gap: 20px;
}

.btn-search {
	font-size: 12px;
	border: 1px solid #000000;
	border-radius: 5px;
	background-color: #F47E29;
	letter-spacing: 2px;
	padding-left: 16px;
	height: 42px;
}

.btn-search:hover {
	background-color: #F47E29 !important;
}

.gap-8 {
	gap: 8px;
}

.search-input {
	border: 1px solid #000000;
	border-radius: 5px;
	height: 42px;
}

.label-username {
	border: 1px solid #000000;
	border-radius: 14.48px;
	padding: 12.5px 34.5px;
	font-size: 12px;
	color: #000000;
	font-weight: 400;
	letter-spacing: 1px;
}

.label-edit {
	border: 1px solid #707070;
	border-radius: 14.48px;
	padding: 12.5px 13px;
	font-size: 12px;
	color: #ffffff;
	font-weight: 400;
	letter-spacing: 1px;
	background-color: #707070;
}

.list-label-user-name {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  margin-top: 26px;

  .list-user-name {
    display: flex;
    align-items: center;
    gap: 31px;
    overflow: hidden;

    .label-user-name {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 16px;
			border: 1px solid #1B4D94;
      background: #1B4D94;
			padding: 10px 10.5px;

      &.active {
        border: 1px solid #068246;
        background: #068246;

        svg {
          fill: #DF2178;
        }

        .text-label-user-name {
          font-size: 12px;
					color: #FFFFFF;
					font-weight: 400;
        }
      }

      .text-label-user-name {
        color: #FFFFFF;
        font-weight: 400;
        font-size: 12px;
      }
    }
  }

  .btn-navigation-user-name {
    display: flex;
    width: 32px;
    height: 32px;
    padding: 6px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #D9D9D9;
    box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
    cursor: pointer;

    svg {
      fill: white;
    }

    &.disabled {
      background: #FFF;

      svg {
        fill: #C3CAD9;
      }
    }
  }
}

.gap-41 {
	gap: 41px;
}

.user-name-detail {
	margin-top: 12px;
	display: flex;
	justify-content: start;
	gap: 12px;

	.label-username-detail {
		background-color: #336990;
		padding: 10px 20px;
		font-size: 12px;
		color: #ffffff;
	}

	.username-detail {
		font-size: 12px;
		background-color: #d9d9d9;
		padding-top: 6px;
		padding-bottom: 6px;
		padding-left: 28px;
		padding-right: 4px;
	}
}

.form-search-date-time {
	margin-top: 12px;
	display: flex;
	gap: 60px;

	.label-search-date-time {
		border: 1px solid #000000;
		border-radius: 5px;
		padding: 20px 17px;
	}

	.search-date-time {
		display: flex;
		gap: 44px;
	}

	.form-select-year {
		width: 110% !important;
		height: 30px;
		border: 0;
		outline: 1px solid #cbd4e1;
		border-right: 12px solid #fff;
	}

	.form-select-date-time {
		height: 30px;
		border: 0;
		outline: 1px solid #cbd4e1;
		border-right: 12px solid #fff;
	}

	.label-setting-report {
		border: 1px solid #000000;
		border-radius: 5px;
		padding: 20px 17px;
		display: flex;
		gap: 40px;

		.search-date-time {
			font-size: 12px;
			padding: 7.5px 34px;
			background-color: #E7E7E7;
			border-radius: 5px;
		}
	}
}

.search-condition {
	margin-top: 23px;
	display: flex;
	justify-content: space-between;

	.condition-search {
		display: flex;
		font-size: 12px;
		gap: 10px;
		align-items: center;

		.status-search-date-time {
			font-size: 12px;
			background-color: #339063;
			border-radius: 2px;
			color: #ffffff;
			padding: 4px 7.5px;
		}

		.status-search-date-time-error {
			font-size: 12px;
			background-color: #FB0000;
			border-radius: 2px;
			color: #ffffff;
			padding: 4px 7.5px;
		}
	}
	.export-csv {
		font-size: 12px;
		padding: 10px 19.5px;
		background-color: #F47E29;
		border-radius: 10px;
		letter-spacing: 1px;
		color: #ffffff;
		line-height: 20px;
	}
}

.result-1 {
	margin-top: 19px;
	gap: 10px;

	.result-title {
		display: flex;
		font-size: 12px;
		padding-left: 20px;
		padding-right: 20px;
		align-items: center;
		border-radius: 5px;
		background-color: #758694;
		color: #ffffff;
		white-space: nowrap;
	}

	.result-title-1 {
		display: flex;
		font-size: 12px;
		padding-left: 15px;
		padding-right: 15px;
		align-items: center;
		border-radius: 5px;
		background-color: #758694;
		color: #ffffff;
		white-space: nowrap;
	}

	.result-title-2 {
		display: flex;
		font-size: 12px;
		padding-left: 12.22px;
		padding-right: 12.22px;
		align-items: center;
		border-radius: 5px;
		background-color: #758694;
		color: #ffffff;
		white-space: nowrap;
	}

	.result-title-3 {
		display: flex;
		font-size: 12px;
		padding-left: 10px;
		padding-right: 10px;
		align-items: center;
		border-radius: 5px;
		background-color: #758694;
		color: #ffffff;
		white-space: nowrap;
	}

	.title-detail {
		display: flex;
		flex-direction: column;
		font-size: 12px;
		border-radius: 2px;
		background-color: #9747FF;
		color: #ffffff;
		margin-left: -7.5px;
		padding: 5px 5px;
		justify-content: center;
	}

	.bg-row-2 {
		margin-left: 0px;
		margin-right: 7px;

		.row {
			margin-right: 0px !important;
		}
	}

	.bg-row-3 {
		margin-left: 0px;
		.row:first-child {
			margin-right: -7px;
		}
	}

	.gap-5 {
		gap: 5px;
	}
}

.bg-row {
	color: #ffffff;
	background-color: #F7E7DC;
	.col {
		padding: 2px 5px;
	}
	color: black;
	div {
		min-height: 30px;
		line-height: 30px;
	}
}

.rs-row {
	margin-right: 0px;
}

.row-ml {
	margin-right: -14.5px;
}

.bg-row-6 {
	margin-right: 20px;
}

.row-detail {
	font-size: 12px;
	font-weight: 400;
	padding: 9px 15px;
	border: 1px solid #000000;
	border-radius: 5px;
}

.input-number {
	width: 70%;
	height: 20px;
	border: 0.5px solid #000000;
}

.input-number-1 {
	width: 100%;
	height: 20px;
	border: 0.5px solid #000000;
}

.input-select {
	height: 20px;
	width: 70%;
	background-color: white !important;
	border: 0.5px solid #000000;
}

.input-last {
	width: 100%;
	height: 24px;
	border: 1px solid #000000;
	border: 0.5px solid #000000;
}

.pt-20 {
	margin-top: 20px;
}

.bg-row-4 {
	margin-right: 17px;
}

.detail-row {

	.col:first-child {
		margin-left: -7.5px;
	}

	.col {
		padding-top: 6px;
	}
}

.pt-10 {
	padding-top: 10px;
}

.article-last {
	gap: 10px;

	.label-input-last {
		background-color: #F7E7DC;
		font-size: 12px;
		color: black;
		padding: 3px 34px;
		white-space: nowrap;
		height: 30px;
		line-height: 30px;
		font-weight: bold;
	}
}