.clients_management, .users_management {
  .form {
    .client-form, .user-form {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;        }
    .scroll-container {
      display: flex;
      overflow: hidden;
      white-space: nowrap;
      box-shadow: rgba(146, 146, 148, 0.1) 0px 0px 16px;
    }
    label {
      margin-bottom: 0 !important;
      display: block;
      text-align: center;
      background-color: #F7E7DC;
    }
    input {
      display: block;
      outline: none;
      border: 1px solid rgb(190, 190, 190);
      padding-left: 5px;
      text-align: center;
    }
    select {
      height: 28px;
      width: 215px;
      text-align: center;
      border: 1px solid rgb(190, 190, 190);
      background-color: white;
    }
    .heading {
      height: 54px;
      line-height: 54px;
      padding: 0 8px;
      background-color: #758694;
      color: white;
    }
    .heading2 {
      height: 54px;
      line-height: 54px;
      padding: 0 8px;
      background-color: #c9ccce;
    }
    .heading3 {
      height: 54px;
      line-height: 54px;
      padding: 0 8px;
      background-color: #e1eaf0;
    }
    .heading4 {
      height: 54px;
      line-height: 54px;
      padding: 0 8px;
      background-color: #f3b285;
    }
    .save-button {
      height: 54px;
      width: auto;
      border-radius: 3px;
      background-color: rgb(36 151 108);
      color: white;
      border: none;
    }
    .small-div {
      width: 120px !important;
    }
    .big-div {
      width: 300px !important;
    }
    // .section {
    //   border: 1px solid #ccd7f8;
    //   background-color: #f3f5fc;
    //   padding: 0.5rem;
    //   border-radius: 0.25rem;
    // }
  }
  .search-container {
    input {
      background-color: #ebebeb;
      border: 1px solid #f3f3f3;
    }
  }
  .btn-search {
    height: 38px;
  }
  .large-input {
    width: 350px;
  }
}
.dark-blue {
  background-color: #405D72 !important;
}
