.change-password-content {
  .title {
    padding-top: 19px !important;
    margin-left: 58px;
    padding: 10px;
  }
  .change_password_form {
    margin-top: 121px;
    width: 523px;
    .required {
      color: red;
    }
    .form-group{
      margin-bottom: 50px;
    }
    .alert-password-danger {
      color: red;
    }
  }
}
.submit_password_button {
  width: 134px;
  height: 40px;
  background-color: #F47E29;
  color: white;
}
