.new-company {
  .system-buttons-container {
    padding: 2rem 3rem 2rem;
    border-radius: 3rem;
    border: 1px solid rgb(182, 182, 182);
    background-color: rgb(235, 235, 235);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  .add-company-button {
    border-radius: 1rem;
    cursor: pointer;
  }
  .add-company-container {
    top: 138%;
    left: 50%;
    transform: translateX(-50%);
    .close-popup {
      right: 0.5rem;
      top: 0.5rem;
      border: none;
      background-color: rgb(243, 243, 243);
    }
  }
  .new-company-form {
    min-width: 254px;
    min-height: 254px;
    background-color: rgb(243, 243, 243);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    form {
      .main-content {
        display: grid;
        grid-template-columns: 370px 370px;
        grid-row: auto auto;
        grid-gap: 20px;
        align-items: baseline;
        justify-content: center;
      }
    }
  }
  input {
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    padding-left: 1rem;
    outline: none;
    border: 1px solid gray;
  }
  .required {
    color: red;
  }
}
.submit_yellow_button {
  width: 150px;
  height: 40px;
  background-color: #F47E29;
  color: white;
  border: none;
  outline: none;
}
.light-hover {
  opacity: 1;
  transition: all 0.1s ease-in;
}
.light-hover:hover {
  opacity: 0.8;
  transition: all 0.1s ease-in;
}
