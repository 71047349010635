.form-forgot-password {
  margin-top: 210px !important;
  width: 500px;
  padding: 50px;
  border-radius: 10px;
  background-color: #d0d0d0;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  margin: auto;
}

.form-header {
  text-align: center;
  margin-bottom: 30px;
}

.form-group {
  margin-bottom: 20px;
}

.form-label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.form-actions {
  text-align: center;
}

.form-submit {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #0d6efd;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.form-submit:hover {
  background-color: #0b5ed7;
}

.login-link {
  margin-top: 20px;
  text-align: center;
}
